<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card class="page-container-card primary--border" outlined>
                    <v-card-title class="title">
                        News
                        <v-spacer></v-spacer>
                        <transition name="animate-css-transition" enter-active-class="animated fadeInRight"
                                    leave-active-class="animated fadeOutRight">
                            <add-button permission="notice-read" @action="form.dialog = true">
                                &nbsp; New News
                            </add-button>
                        </transition>
                    </v-card-title>
                    <v-data-table
                            :headers="headers"
                            :items="form.items.data"
                            footer-props.items-per-page-options="rowsPerPageItems"
                            :options.sync="pagination"
                            :server-items-length="form.items.meta.total"
                            :loading="form.loading">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left">{{ item.title }}</td>
                                <td class="text-xs-left">{{ item.excerpt }}</td>
                                <td class="text-xs-left">{{ item.description }}</td>
                                <td class="text-xs-left">
                                    <span v-if="item.status === 'active'"><v-icon color="green">check_circle</v-icon></span>
                                    <span v-if="item.status === 'inactive'"><v-icon color="red">cancel</v-icon></span>
                                </td>
                                <td class="text-xs-left"><a :href="item.cover" target="_blank">{{item.cover ? 'File':''}}</a> </td>
                                <td class="text-xs-left">{{ item.creator }}</td>

                                <td class="text-xs-right">
                                    <edit-button permission="notice-read"
                                                @agree="form.edit(item), form.cover = ''"/>
                                    <delete-button permission="notice-read" @agree="form.delete(item.id)"/>
                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" persistent max-width="650">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Add/Update</span>
                    </v-card-title>
                    <v-card-text class="pb-1">
                        <v-form ref="form" @submit.prevent="save"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save"
                                lazy-validation>
                            <v-text-field outlined dense autocomplete="off" label="Title*"
                                          required class="pa-0 pt-3"
                                          v-model="form.title"
                                          name="title" :error-messages="form.errors.get('title')"/>

                            <v-select outlined dense :items="statuses" class="pa-0 pt-3" label="Status"
                                      name="status" v-model="form.status" :error-messages="form.errors.get('status')"/>

                            <v-text-field outlined dense autocomplete="off" label="Excerpt*"
                                          required class="pa-0 pt-3"
                                          v-model="form.excerpt"
                                          name="title" :error-messages="form.errors.get('excerpt')"/>

                            <wysiwyg v-model="form.description"/>

                            <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>

                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" text @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" text @click="save">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'

    export default {
        data: () => ({
            form: new Form({
                title: '',
                description: '',
                status: '',
                excerpt: '',
                cover: '',
            }, '/api/news'),
            search: null,
            rowsPerPageItems: [5, 10, 25, 50, 75],
            pagination: {},
            statuses: [{value: 'active', text: 'Active'}, {value: 'inactive', text: 'Inactive'}],
            headers: [
                {text: '#', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'Title', align: 'left', value: 'title'},
                {text: 'Excerpt', align: 'left', value: 'excerpt'},
                {text: 'Description', align: 'left', value: 'description'},
                {text: 'Status', align: 'left', value: 'status'},
                {text: 'File', align: 'left', value: 'file'},
                {text: 'Creator', align: 'left', value: 'file'},
                {text: 'Action', align: 'right', sortable: false, width: 200}
            ],
        }),

        computed: {
            ...mapState(['batch'])
        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            }
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' + Object.keys(json).map(key => {
                    if (typeof json[key] === 'object') {
                        let vals = [...json[key]];
                        if (vals.length) return encodeURIComponent(key) + '=' + encodeURIComponent(vals.join(','));
                        else return null;
                    }
                    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                }).join('&');
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            },

            save() {
                this.form.store();
            },
            handleFileUpload() {
                this.form.cover = this.$refs.file.files[0];
            },

        }
    }
</script>
<style lang="scss" scoped>
    .public {
        background: red;
        padding: 3px;
        border-radius: 5px;
        color: white;
    }
    .private {
        background: #fb8c00 !important;
        padding: 3px;
        border-radius: 5px;
        color: white;
    }
    .both {
        background: green;
        padding: 3px;
        border-radius: 5px;
        color: white;
    }
</style>